export function hasUpperCase(value) {
  return /[A-Z]/.test(value);
}

export function hasLowerCase(value) {
  return /[a-z]/.test(value);
}

export function hasNumber(value) {
  return /[0-9]/.test(value);
}

export const specialChars = "!@#-._+";

export function hasSpecialCharacter(value) {
  let re = new RegExp(`[${specialChars}]`);
  return re.test(value);
}
