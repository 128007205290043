<template>
  <v-card width="400" class="mx-auto mt-5">
    <v-card-title>
      <h1 class="display-1">Reset Password</h1>
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent="resetPassword">
        <v-text-field
          autofocus
          v-model="password1"
          prepend-icon="mdi-lock-reset"
          label="New password"
          :type="showPassword1 ? 'text' : 'password'"
          :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword1 = !showPassword1"
          @blur="$v.password1.$touch()"
        />
        <div class="red--text text--lighten-1" v-if="$v.password1.$error">
          <div v-if="!$v.password1.required">
            <v-icon color="red">mdi-alert-circle-outline</v-icon>
            Password required
          </div>

          <div v-if="!$v.password1.minLength">
            <v-icon color="red">mdi-alert-circle-outline</v-icon>
            Password must be at least 8 characters
          </div>

          <div v-if="!$v.password1.hasUpperCase">
            <v-icon color="red">mdi-alert-circle-outline</v-icon>
            Must have an uppercase character
          </div>

          <div v-if="!$v.password1.hasLowerCase">
            <v-icon color="red">mdi-alert-circle-outline</v-icon>
            Must have a lowercase character
          </div>

          <div v-if="!$v.password1.hasNumber">
            <v-icon color="red">mdi-alert-circle-outline</v-icon>
            Must have at least 1 number
          </div>

          <div v-if="!$v.password1.hasSpecialCharacter">
            <v-icon color="red">mdi-alert-circle-outline</v-icon>
            Must have at least 1 special character
          </div>
        </div>

        <v-text-field
          v-model="password2"
          :type="showPassword2 ? 'text' : 'password'"
          label="New Password (again)"
          prepend-icon="mdi-lock-reset"
          :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword2 = !showPassword2"
          @blur="$v.password2.$touch()"
        />
        <div class="spacer"></div>

        <div
          class="red--text text--lighten-1"
          v-if="$v.password2.$error && !$v.password2.required"
        >
          <v-icon color="red">mdi-alert-circle-outline</v-icon>
          Repeat is required
        </div>

        <v-btn
          type="submit"
          color="success"
          name="button"
          :disabled="$v.$invalid"
        >
          Submit
        </v-btn>

        <div v-if="error" class="red--text text--lighten-1">
          <v-icon color="red">mdi-alert-circle-outline</v-icon>
          {{ getLoginErrorMsg() }}
        </div>
      </v-form>

      <div class="spacer"></div>

      <div>
        <ul>
          <li>Must contain at least 8 characters</li>
          <li>Must contain both upper and lower case characters</li>
          <li>Must contain at least 1 number</li>
          <li>Must contain at least special character {{ getSpecialChars }}</li>
        </ul>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import {
  hasUpperCase,
  hasLowerCase,
  hasNumber,
  specialChars,
  hasSpecialCharacter,
} from "@/lib/validators/password_validators";

export default {
  name: "ChangePassword",

  data() {
    return {
      password1: "",
      password2: "",
      error: null,
      showPassword1: false,
      showPassword2: false,
    };
  },

  validations: {
    password1: {
      required,
      minLength: minLength(8),
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasSpecialCharacter,
    },
    password2: {
      required,
      sameAsPassword: sameAs("password1"),
    },
  },

  methods: {
    resetPassword() {
      return this.$store
        .dispatch("user/resetPassword", {
          password: this.password1,
          token: this.getToken(),
        })
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch((err) => {
          this.error = err.response;
        });
    },

    getLoginErrorMsg() {
      if (this.error.status == 401) {
        return "Invalid username or password";
      } else {
        return "failed";
      }
    },

    getToken: function () {
      return this.$route.query.token;
    },

    getSpecialChars() {
      return specialChars;
    },
  },
};
</script>

<style scoped>
.spacer {
  height: 25px;
}
</style>
